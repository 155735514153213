var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1000,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "规则名称",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["ruleName", _vm.validatorRules.ruleName],
                        expression: "['ruleName', validatorRules.ruleName]",
                      },
                    ],
                    attrs: { placeholder: "请输入规则名称" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "规则Code",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["ruleCode", _vm.validatorRules.ruleCode],
                        expression: "['ruleCode', validatorRules.ruleCode]",
                      },
                    ],
                    attrs: { placeholder: "请输入规则Code" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "规则描述",
                  },
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["ruleDescription", {}],
                        expression: "['ruleDescription', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入规则描述" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tabs",
            {
              model: {
                value: _vm.tabs.activeKey,
                callback: function ($$v) {
                  _vm.$set(_vm.tabs, "activeKey", $$v)
                },
                expression: "tabs.activeKey",
              },
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: _vm.tabs.design.key,
                  attrs: { tab: "局部规则", forceRender: "" },
                },
                [
                  _c("a-alert", {
                    attrs: {
                      type: "info",
                      showIcon: "",
                      message: "局部规则按照你输入的位数有序的校验。",
                    },
                  }),
                  _c("j-editable-table", {
                    ref: "designTable",
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      dragSort: "",
                      rowNumber: "",
                      maxHeight: 240,
                      columns: _vm.tabs.design.columns,
                      dataSource: _vm.tabs.design.dataSource,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function (props) {
                          return [
                            _c("my-action-button", {
                              attrs: { rowEvent: props },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: _vm.tabs.global.key,
                  attrs: { tab: "全局规则", forceRender: "" },
                },
                [
                  _c("j-editable-table", {
                    ref: "globalTable",
                    attrs: {
                      dragSort: "",
                      rowNumber: "",
                      actionButton: "",
                      maxHeight: 240,
                      columns: _vm.tabs.global.columns,
                      dataSource: _vm.tabs.global.dataSource,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "actionButtonAfter",
                        fn: function () {
                          return [
                            _c("a-alert", {
                              staticStyle: { "margin-bottom": "8px" },
                              attrs: {
                                type: "info",
                                showIcon: "",
                                message:
                                  "全局规则可校验用户输入的所有字符；全局规则的优先级比局部规则的要高。",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "action",
                        fn: function (props) {
                          return [
                            _c("my-action-button", {
                              attrs: { rowEvent: props, allowEmpty: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }